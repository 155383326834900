import React from 'react';

import Layout from '../../components/layout/Layout';
import BlogRoll from '../../components/blog/BlogRoll';

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <section className='flex-grow'>
          <div className='max-w-7xl mx-auto px-4 mt-4'>
            <div className='py-6 text-center'>
              <h1 className='text-lg text-yellow-700 font-semibold tracking-wide uppercase'>
                today
              </h1>
              <h2 className='mt-2 text-6xl font-extrabold tracking-tight text-white'>
                blog
              </h2>
              <h3 className='mt-4 max-w-2xl mx-auto text-2xl font-normal text-gray-400'>
                latest stories
              </h3>
            </div>
            <div className=''>
              <BlogRoll />
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
